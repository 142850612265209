import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment'
import {
  successActivitiLogDictinory,
  GET_ACTIVITI_LOG_DICTINORY,
  SET_DATES_RANGE,
} from '@/store/actions/actionLog'
import { GET_ACTION_LOG_DICTINORY_URL} from '@/constants/apiRoutes'
import { getActivitiLogFilterValue, getSelectedElement, getDatesRange } from '@/store/selectors/actionLog'




function* getActivitiLogDictinorySaga({payload}) {
  const filterValue = yield select(getActivitiLogFilterValue)
  const {userId, tenantId} = yield select(getSelectedElement)
  const { start, end } = yield select(getDatesRange)
  try {
    const response = yield request({
      url: GET_ACTION_LOG_DICTINORY_URL({
        params: {
          tenantId: tenantId ? tenantId : payload.tenantId,
          actions: filterValue['action']?.map((item) => item.value),
          appNames: filterValue['appName']?.map((item) => item.value),
          objectTypes: filterValue['objectType']?.map((item) => item.value),
          userIds: filterValue['fullName']?.map((item) => item.value),
          fromDate: start && moment(start).format('DD.MM.YYYY'),
          toDate: end && moment(end).format('DD.MM.YYYY'),
        }
      }),
      method: 'get',
    })


    const dictionaryActions = response.data['ACTIONS'].map(item => {
      return {
          title: item.value,
          value: item.value,
      }
    })

    const dictionaryAppNames = response.data['APP_NAMES'].map(item => {
      return {
          title: item.value,
          value: item.value,
      }
    })

    const dictionaryObjectTypes = response.data['OBJECT_TYPES'].map(item => {
      return {
          title: item.value,
          value: item.value,
      }
    })

    const dictionaryUsers = response.data['USERS'].map(item => {
      return {
          title: item.value,
          value: item.id,
      }
    })
    yield put(successActivitiLogDictinory({fullName: dictionaryUsers, objectType: dictionaryObjectTypes, appName: dictionaryAppNames, action: dictionaryActions}))

  } catch (error) {
    console.log(error)
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ACTIVITI_LOG_DICTINORY.REQUEST, getActivitiLogDictinorySaga)
  yield takeLatest(SET_DATES_RANGE, getActivitiLogDictinorySaga)
}
